// dummyProjects.js

import forst from '../assets/images/forst.jpg';
import theman from '../assets/images/theman.webp';

const dummyProjects = [
    {
        id: 1,
        title: 'Support a family in need after the storm',
        donations: 33,
        raised: 12500,
        goal: 20000,
        image: theman,
    },
    {
        id: 2,
        title: 'Build a community center in rural Africa',
        donations: 40,
        raised: 15000,
        goal: 30000,
        image: forst,
    },
    {
        id: 3,
        title: 'Healthcare for underprivileged communities',
        donations: 50,
        raised: 12000,
        goal: 25000,
        image: forst,
    },
    {
        id: 4,
        title: 'Education for children in remote villages',
        donations: 65,
        raised: 48000,
        goal: 50000,
        image: theman,
    },
    {
        id: 5,
        title: 'Clean water project for desert communities',
        donations: 55,
        raised: 18000,
        goal: 35000,
        image: forst,
    },
    {
        id: 6,
        title: 'Empower women through skill training',
        donations: 37,
        raised: 22000,
        goal: 45000,
        image: theman,
    },
    {
        id: 7,
        title: 'Provide meals for homeless people',
        donations: 80,
        raised: 27000,
        goal: 40000,
        image: forst,
    },
    {
        id: 8,
        title: 'Rebuild homes for flood victims',
        donations: 29,
        raised: 10000,
        goal: 20000,
        image: theman,
    },
    {
        id: 9,
        title: 'Equip schools with technology',
        donations: 60,
        raised: 32000,
        goal: 60000,
        image: forst,
    },
    {
        id: 10,
        title: 'Medical support for children with cancer',
        donations: 95,
        raised: 40000,
        goal: 75000,
        image: theman,
    },
];

export default dummyProjects;
