import React, { useState } from 'react';
import { Container, Typography, Button, Box, TextField, Slider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './DonatePage.module.css';
import DetailsModal from '../containers/donations/DetailsModal';
import NotificationModal from '../containers/donations/NotificationModal';
import SuccessModal from '../containers/donations/SuccessModal';


const DonatePage = () => {
    const { state } = useLocation();
    const { project } = state || {};
    const navigate = useNavigate();

    const [donationAmount, setDonationAmount] = useState('');
    const [tipPercentage, setTipPercentage] = useState(17);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');

    const presetAmounts = [5, 10, 20, 50, 100, 1000, 1500, 2000];

    const handlePresetAmountClick = (amount) => {
        setDonationAmount(amount);
    };

    const handleDonationInputChange = (e) => {
        const value = e.target.value ? parseFloat(e.target.value) : '';
        setDonationAmount(value);
    };

    const handleTipChange = (event, newValue) => {
        setTipPercentage(newValue);
    };

    const calculateTip = () => (donationAmount * tipPercentage) / 100 || 0;
    const calculateTotal = () => parseFloat(donationAmount || 0) + calculateTip();

    const handleDonateNowClick = () => {
        setOpenDetailsModal(true);
    };

    const handlePayNowClick = () => {
        setOpenDetailsModal(false);
        setOpenNotificationModal(true);

        setTimeout(() => {
            setOpenNotificationModal(false);
            setOpenSuccessModal(true);
        }, 3000);
    };

    const handleSuccessModalClose = () => {
        setOpenSuccessModal(false);
        navigate(-1);
    };

    return (
        <Container className={styles.mainContainer}>
            <Box className={styles.innerBox}>
                <Typography variant="h6" gutterBottom>
                    You are supporting <strong>{project?.title}</strong>
                </Typography>
                <Typography variant="body1" color="textSecondary" style={{ marginBottom: '20px' }}>
                    your donations are secure and protected
                </Typography>

                <Box marginTop={3}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '17px' }} gutterBottom>
                        Enter your donation
                    </Typography>
                    <Box className={styles.presetAmountContainer} style={{ marginTop: "20px" }}>
                        {presetAmounts.map((amount) => (
                            <Button
                                key={amount}
                                style={{
                                    background: donationAmount === amount ? "#00A6A6" : "white",
                                    color: donationAmount == amount ? "white" : "black",
                                    borderColor: donationAmount == amount ? "NONE" : "grey",
                                    fontWeight: "bold"
                                }}
                                variant={donationAmount === amount ? "contained" : "outlined"}
                                onClick={() => handlePresetAmountClick(amount)}
                                className={styles.presetAmountButton}
                            >
                                ${amount}
                            </Button>
                        ))}
                    </Box>
                    <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={donationAmount}
                        onChange={handleDonationInputChange}
                        inputMode="numeric"
                        InputProps={{
                            startAdornment: (
                                <Typography style={{
                                    fontSize: "20px", fontWeight: "bold", marginRight: "8px",
                                    color: "grey"
                                }}>$</Typography>
                            ),
                            endAdornment: (
                                <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>.00</Typography>
                            ),
                            style: { fontSize: "20px", fontWeight: "bold", textAlign: "center" },
                        }}
                        style={{ marginTop: '15px' }}
                    />
                </Box>

                <Box marginTop={4} textAlign="left">
                    <Typography variant="h7" style={{ fontWeight: 'bold', fontSize: '17px' }} gutterBottom>
                        Help SomFundMe services
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px', fontSize: '13px', marginTop: "8px" }}>
                        Help us help others who use our platform to fundraise and manage their projects.
                    </Typography>
                    <Slider
                        disabled={donationAmount === ''}
                        value={tipPercentage}
                        onChange={handleTipChange}
                        step={1}
                        min={5}
                        max={30}
                        valueLabelDisplay="auto"
                        aria-labelledby="tip-slider"
                        style={{ margin: '10px 0', color: "lightGrey", height: "4px" }}
                    />
                    <Typography variant="caption" display="block" textAlign="center">
                        {tipPercentage}% tip
                    </Typography>
                </Box>

                <Box marginTop={4} display="flex" flexDirection="column" style={{ borderTop: '1px solid #e0e0e0', paddingTop: '15px' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="subtitle1">Your donation</Typography>
                        <Typography variant="body2">${donationAmount ? parseFloat(donationAmount).toFixed(2) : '0.00'}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="subtitle1">SomFundMe service (+ tip)</Typography>
                        <Typography variant="body2">${calculateTip().toFixed(2)}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="subtitle1" fontWeight="bold">Total amount</Typography>
                        <Typography variant="subtitle1" fontWeight="bold">${calculateTotal().toFixed(2)}</Typography>
                    </Box>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleDonateNowClick}
                    style={{
                        backgroundColor: '#00A6A6',
                        fontWeight: 'bold',
                        marginTop: '20px',
                        height: '50px',
                        fontSize: '16px'
                    }}
                >
                    DONATE NOW
                </Button>

                <DetailsModal
                    open={openDetailsModal}
                    onClose={() => setOpenDetailsModal(false)}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    name={name}
                    setName={setName}
                    onPayNowClick={handlePayNowClick}
                />

                <NotificationModal open={openNotificationModal} />

                <SuccessModal open={openSuccessModal} onClose={handleSuccessModalClose} />
            </Box>
        </Container>
    );
};

export default DonatePage;
