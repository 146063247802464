import {  Typography, Button, Box, Modal, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import { MdClose} from 'react-icons/md';
import styles from '../../Pages/ProjectDetails.module.css';


export default function DonationsModal ({openDonationsModal, handleCloseDonationsModal, handleDonateNowClick, project}) {

    return (
    <Modal open={openDonationsModal} onClose={handleCloseDonationsModal}>
    <Box className={`${styles.modalContainer} ${styles.responsiveModal}`} style={{ borderRadius: "12px", padding: "10px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
            <Typography variant="h6" fontWeight="bold">Donations ({project.donations})</Typography>
            <MdClose onClick={handleCloseDonationsModal} style={{ fontSize: "24px", color: "black", cursor: "pointer" }} />
        </Box>
        <Box className={styles.scrollableList}>
            <List>
                {[...Array(24)].map((_, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                <span role="img" aria-label="donor">👤</span>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Calasey Jimcaale"
                            secondary={<><Typography component="span" fontWeight="bold">$5</Typography> - 25 mins ago</>}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
        <Box padding={2} style={{ paddingBottom: "16px" }}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ backgroundColor: '#00A6A6', fontWeight: 'bold', fontSize: '16px', height: '50px' }}
                onClick={handleDonateNowClick}
            >
                DONATE NOW
            </Button>
        </Box>
    </Box>
</Modal>
    )
}
