import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, Button, Box, CircularProgress } from '@mui/material';
import styles from './ProjectDetails.module.css';
import dummyProjects from '../data/dummyProjects';
import ShareModal from '../containers/modals/ShareModal';
import ContactModal from '../containers/modals/ContactModal';
import DonationsModal from '../containers/modals/DonationsModal';
import Organization from '../containers/projectDetails/Organization';

const ProjectDetails = () => {

    const { state } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const hardcodedImage = "https://firebasestorage.googleapis.com/v0/b/deentire-application.appspot.com/o/0000noteImages%2Fforst.jpg?alt=media&token=f807016c-a29c-479b-8598-b91984a1c250";

    const [project, setProject] = useState(state?.project || null);
    const [progress, setProgress] = useState(0);
    const [openDonationsModal, setOpenDonationsModal] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [openContactModal, setOpenContactModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false); // State for share modal

    const descriptionText = "We are raising funds to launch a 10-week support group for Black women, facilitated by licensed mental health professionals entitled the 'Burn The Cape' support group. This group is designed to address the unique challenges Black women face, including stress from racism, activism fatigue, stereotypes, and systemic barriers. Our goal is to create a safe, supportive space for healing, growth, and empowerment.";

    const targetProgress = project ? (project.raised / project.goal) * 100 : 0;

    useEffect(() => {
        if (!project) {
            const fetchedProject = dummyProjects.find((p) => p.id === parseInt(id));
            setProject(fetchedProject);
        }
    }, [id, project]);

    useEffect(() => {
        setProgress(0);
        const timer = setInterval(() => {
            setProgress((prev) => {
                if (prev < targetProgress) {
                    return Math.min(prev + 1, targetProgress);
                } else {
                    clearInterval(timer);
                    return prev;
                }
            });
        }, 10);

        return () => clearInterval(timer);
    }, [targetProgress]);

    const handleOpenDonationsModal = () => setOpenDonationsModal(true);
    const handleCloseDonationsModal = () => setOpenDonationsModal(false);
    const handleOpenContactModal = () => setOpenContactModal(true);
    const handleCloseContactModal = () => setOpenContactModal(false);
    const handleOpenShareModal = () => setOpenShareModal(true);
    const handleCloseShareModal = () => setOpenShareModal(false);

    const toggleDescription = () => setShowFullDescription(!showFullDescription);

    const handleDonateNowClick = () => {
        navigate('/donate', { state: { project } });
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
    };

    if (!project) return <Typography variant="h6">Project not found</Typography>;

    return (
        <Container className={styles.projectDetails}>
            <img src={hardcodedImage} alt={project.title} className={styles.projectImage} />
            <Typography className={styles.projectTitle} align="left" style={{ fontWeight: 'bold', fontSize: '22px' }} gutterBottom>
                {project.title}
            </Typography>

            <div className={styles.sidebar}>
                <Box className={styles.progressSection}>
                    <Box>
                        <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            ${project.raised.toLocaleString()} raised
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={{ fontSize: '15px' }}>
                            of ${project.goal.toLocaleString()} goal - {project.donations} donations
                        </Typography>
                    </Box>

                    <Box position="relative" display="inline-flex">
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size={70}
                            thickness={5}
                            sx={{ color: '#E6E6E6' }}
                        />
                        <CircularProgress
                            variant="determinate"
                            value={progress}
                            size={70}
                            thickness={5}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                color: '#00A651',
                                '& .MuiCircularProgress-circle': {
                                    strokeLinecap: 'round',
                                },
                            }}
                        />
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="caption" component="div" color="textSecondary">
                                {`${Math.round(progress)}%`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={1}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        style={{
                            height: '50px',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            color: '#00A6A6',
                            border: '1px solid #00A6A6',
                            marginTop: '8px'
                        }}
                        onClick={handleOpenShareModal}
                    >
                        SHARE
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor: '#00A6A6', fontWeight: 'bold', fontSize: '16px', height: '50px' }}
                        onClick={handleDonateNowClick}
                    >
                        DONATE NOW
                    </Button>
                    <Typography
                        variant="body2"
                        color="primary"
                        className={styles.donatersLink}
                        align="left"
                        style={{
                            color: 'black',
                            textDecoration: 'underline',
                            fontSize: '15px',
                            marginRight: 'auto',
                            cursor: 'pointer'
                        }}
                        onClick={handleOpenDonationsModal}
                    >
                        See list of donaters
                    </Typography>
                </Box>
            </div>

            <Box marginTop={3} align="left">
                <Typography variant="body1" className={styles.projectDescription}>
                    {showFullDescription ? descriptionText : `${descriptionText.substring(0, 270)}...`}
                </Typography>
                <Typography variant="body2" color="primary" style={{ cursor: 'pointer', marginTop: '8px' }} onClick={toggleDescription}>
                    {showFullDescription ? "Show Less" : "Read More"}
                </Typography>
            </Box>
                    
            <Organization handleOpenContactModal = {handleOpenContactModal}/>

            {openShareModal && <ShareModal openShareModal={openShareModal} handleCloseShareModal={handleCloseShareModal} handleCopyLink={handleCopyLink} />}

            {openDonationsModal && <DonationsModal openDonationsModal={openDonationsModal} handleCloseDonationsModal={handleCloseDonationsModal}
                handleDonateNowClick={handleDonateNowClick} project={project} />}

            {openContactModal && <ContactModal openContactModal={openContactModal} handleCloseContactModal={handleCloseContactModal} />}

        </Container>
    );
};

export default ProjectDetails;
