import {  Typography, Button, Box, Modal } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {MdPhone } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 400,
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: 24,
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            width: "100%"
        },
    },
}));

export default function ContactModal ({openContactModal, handleCloseContactModal}) {
    const classes = useStyles()

    return (
        <Modal open={openContactModal} onClose={handleCloseContactModal}>
        <Box className={classes.modalContent}>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ textAlign: 'center' }}>
                <Box display="flex" justifyContent="center" alignItems="center" border="2px solid #00A6A6" borderRadius="50%" width="60px" height="60px" mb={2}>
                    <MdPhone style={{ color: '#00A6A6', fontSize: '30px' }} />
                </Box>
                <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '0px' }}>Samafale Organization</Typography>
                <Typography variant="body2" color="textSecondary" style={{ fontSize: '16px' }}>+252 616549198</Typography>
                <Button onClick={handleCloseContactModal} variant="contained" color="primary" style={{ marginTop: '30px', fontWeight: "bold", backgroundColor: '#00A6A6', fontWeight: 'bold', width: '100%' }}>
                    CLOSE
                </Button>
            </Box>
        </Box>
    </Modal>
    )
}
