import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './SignupAndLogin/loginSlice';

const store = configureStore({
  reducer: {
    login: loginReducer, // Ensure this key matches the slice's name
  },
});

export default store;
