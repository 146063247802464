// DashboardPage.js
import React from 'react';
import { Container, Typography, Button, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dummyProjects from '../data/dummyProjects';
import styles from './Dashboard.module.css';
import { RiCoinsLine } from "react-icons/ri";


const DashboardPage = () => {
    const navigate = useNavigate();

    const statusCards = [
        { title: "amount raised", value: "20,000", isMoney: true },
        { title: "project count", value:  "10", isMoney: false },
      ];

    const projectClickHandler = (project) => {
        navigate(`/project/${project.id}`, { state: { project } });
    };

    return (
        <div style={{ background: 'white', width: '100%', marginTop: "5px" }}>

<Container>
                <h2 className={styles.projectTitle} style={{ marginTop: '25px' }}>
                    Dashboard
                </h2>
            </Container>

        <div  style={{ width: "100%", margin:"20px auto", padding: "0px 12px"}}>
          <div className= {styles.statsDivCards} style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", width: "100%", gap: "20px" }}>
            {statusCards?.map(s => {
              return <StatusCard key={s.title} data={s} />
            })}
          </div>
          </div>


            <Container>
                <h2 className={styles.projectTitle} style={{ marginTop: '35px', color: "grey", fontSize: "20px" }}>
                    Latest projects
                </h2>
            </Container>

            <Container>
                <div className={styles.projectGrid}>
                    {dummyProjects.map((project) => (
                        <ProjectCard key={project.id} project={project} onProjectClick={projectClickHandler} />
                    ))}
                </div>
            </Container>
        </div>
    );
};

const ProjectCard = ({ project, onProjectClick }) => {
    return (
        <div className={styles.projectCard} >
            <img src={project.image} alt={project.title} className={styles.projectImage} />
            <div className={styles.projectContent}>
                <p className={styles.donationsText}>{project.donations} donations</p>
                <p className={styles.projectName}>{project.title}</p>
                <div className={styles.buttonGroup}>
                    <Button variant="outlined" color="primary" className={styles.projectButton}
                    onClick={() => onProjectClick(project)} style = {{borderColor: "#00A6A6", color: "#00A6A6"}}>Stats</Button>
                    <Button variant="outlined"  className={styles.projectButton}
                    style = {{borderColor: "black", color: "black", }}>Share</Button>
                </div>
            </div>
        </div>
    );
};

function StatusCard({ data }) {
    return (
      <div className = {styles.dashboardCard}style={{
        display: "flex", background: "white", padding: "20px", borderRadius: "8px", border: "1px solid #B3B3B3",
        width: "100%", flexDirection: "row", gap: "15px",
      }}>
        {/* <Avatar style={{ background: "#9CE1FB" }}> */}
          <RiCoinsLine style={{ color: "#00A6A6", fontSize: "30px", alignSelf:"center" }} />
        {/* </Avatar> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px", }}>
            {data?.isMoney && "$"}{data.value} </Typography>
          <Typography style={{ color: "#979797", fontSize: "14px", }}> {data.title}</Typography>
        </div>
      </div>
    );
  }

export default DashboardPage;
