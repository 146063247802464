import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { setActiveUser, setIsLogin, setToken } from './SignupAndLogin/loginSlice';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './Pages/HomePage';
import ProjectDetails from './Pages/ProjectDetails';
import MyAccount from './Pages/MyAccount';
import Profile from './Pages/Profile';
import DashboardPage from './Pages/DashboardPage';
import SignupAndLogin from './SignupAndLogin/SignupAndLogin';
import ProtectedRoute from './ProtectedRoute';
import DonatePage from './Pages/DonatePage';
import Signup from './SignupAndLogin/Signup';
import CreateProject from './Pages/CreateProject';

const App = () => {
    const [isSearching, setIsSearching] = useState(false);
    const dispatch = useDispatch();

    const toggleSearch = () => {
        setIsSearching(prev => !prev);
    };

    const resetSearch = () => {
        setIsSearching(false);
    };

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        const storedToken = localStorage.getItem("token");

        if (storedUser && storedToken) {
            dispatch(setActiveUser(JSON.parse(storedUser)));
            dispatch(setToken(storedToken));
            dispatch(setIsLogin(true));
        }
    }, [dispatch]);

    return (
        <Router>
            <Header onSearchToggle={toggleSearch} onLogoClick={resetSearch} />
            <Routes>
                <Route path="/" element={<HomePage isSearching={isSearching} />} />
                <Route path="/project/:id" element={<ProjectDetails />} />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <DashboardPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/my-account"
                    element={
                        <ProtectedRoute>
                            <MyAccount />
                        </ProtectedRoute>
                    }
                />
                 <Route path="/donate" element={<DonatePage />} />
                 <Route path="/create" element={<CreateProject />} />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    }
                />
                <Route path="/signIn" element={<SignupAndLogin />} />
                <Route path="/signUp" element={<Signup />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
