import { Typography, Button, Box } from '@mui/material';

import styles from '../../Pages/ProjectDetails.module.css';
import { constants } from '../../Helpers/constantsFile';

export default function Organization ({handleOpenContactModal}) {
    
    return (
     <Box marginTop={4} className={styles.organizerSection}>
     <Typography variant="subtitle1" gutterBottom style={{ fontSize: '16px' }}>
         Organizer
     </Typography>
     <Box display="flex" alignItems="center">
         <img src="https://firebasestorage.googleapis.com/v0/b/deentire-application.appspot.com/o/0000noteImages%2Forg.webp?alt=media&token=7e49290f-6043-484f-98f3-f600a403beb3"
             alt="Organizer Logo" className={styles.organizerLogo} />
         <Box style={{ marginLeft: '6px' }}>
             <Typography variant="body1">Same-fale Organization</Typography>
             <Button variant="outlined" size="small" style={{
                 marginTop: '4px',
                 borderColor: constants.pColor, color: constants.pColor
             }} onClick={handleOpenContactModal}>
                 Contact
             </Button>
         </Box>
     </Box>
 </Box>
    )
 }