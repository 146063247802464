import React, { useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { MdCloudUpload } from 'react-icons/md';
import { constants } from '../Helpers/constantsFile';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: theme.spacing(4),
        maxWidth: '500px',
        margin: '20px auto',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    header: {
        fontWeight: 'bold',
        fontSize: '24px',
        marginBottom: theme.spacing(1),
    },
    subHeader: {
        color: '#777',
        marginBottom: theme.spacing(3),
    },
    field: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    uploadButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(1.5),
        backgroundColor: '#E0E0E0',
        borderRadius: '8px',
        cursor: 'pointer',
        color: '#333',
        fontWeight: 'bold',
    },
    createButton: {
        backgroundColor: constants.pColor,
        color: '#fff',
        fontWeight: 'bold',
        padding: theme.spacing(1.5),
        width: '100%',
        '&:hover': {
            backgroundColor: '#00a2a2',
        },
    },
}));

const CreateProject = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const isLogin = useSelector((state) => state.login.isLogin);

    useEffect(() => {
        if (!isLogin) {
            navigate('/signIn', { state: { from: location.pathname } }); // Pass the intended path
        }
    }, [isLogin, navigate, location]);

    if (!isLogin) {
        return null; // Prevents the component from rendering if the user is not logged in
    }

    return (
        <Box className={classes.container}>
            <Typography style={{ fontWeight: "bold", fontSize: "24px", lineHeight: "1.2", fontFamily: "Ubuntu, sans-serif" }}>
                Let's Begin Your Fundraising Journey
            </Typography>
            <Typography className={classes.subHeader} style={{ fontSize: "18px", marginTop: "8px" }}>
                Follow the step-by-step guide
            </Typography>

            <Typography style={{ fontSize: '18px', marginTop: '40px', fontWeight: "bold" }}>
                Let’s create your project now!
            </Typography>

            <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", marginTop: "20px" }}>
                <TextField
                    label="Enter Project Title"
                    variant="outlined"
                    className={classes.field}
                />
                <TextField
                    label="Enter Project Goal"
                    variant="outlined"
                    className={classes.field}
                    type="number"
                />
                <Box className={classes.uploadButton}>
                    <MdCloudUpload style={{ marginRight: '10px', fontSize: "18px", color: constants.pColor }} />
                    Upload Image
                </Box>
                <TextField
                    label="Enter Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    className={classes.field}
                />
            </div>
            <Button variant='contained' style={{ width: "100%", fontSize: "16px", fontWeight: "bold", background: constants.pColor,
                height: "45px", marginTop: "15px"
            }}>
                Create Project
            </Button>
        </Box>
    );
};

export default CreateProject;
