import React, { useState } from 'react';
import { Avatar, Box, Button, Modal, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { constants } from '../Helpers/constantsFile';

const useStyles = makeStyles((theme) => ({
    profileContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2), // Slightly smaller padding on small screens
        },
        borderRadius: '10px',
        backgroundColor: 'white',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px', // Adjust width for desktop view
        width: '100%', // Responsive width for mobile view
        margin: '30px auto',
    },
    profileImage: {
        width: theme.spacing(25), // Bigger avatar size
        height: theme.spacing(18),
        marginBottom: theme.spacing(2),
    },
    changeImageLink: {
        color: '#02BFC6',
        cursor: 'pointer',
        marginBottom: theme.spacing(4),
        textDecoration: 'underline',
    },
    formField: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    updateButton: {
        backgroundColor: '#02BFC6',
        color: 'white',
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1.5),
        fontSize: '16px',
        '&:hover': {
            backgroundColor: '#0298A2',
        },
    },
    changePasswordButton: {
        width: '100%',
        color: '#000',
        borderColor: '#000',
        textTransform: 'none',
        padding: theme.spacing(1.5),
        fontSize: '16px',
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        padding: theme.spacing(4),
        borderRadius: '10px',
        boxShadow: 24,
    },
    modalTitle: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
    },
    modalSubtitle: {
        marginBottom: theme.spacing(3),
        color: '#666',
    },
    modalField: {
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        backgroundColor: '#02BFC6',
        color: 'white',
        width: '100%',
        padding: theme.spacing(1.5),
        fontSize: '16px',
        '&:hover': {
            backgroundColor: '#0298A2',
        },
    },
}));

const Profile = () => {
    const classes = useStyles();
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

    const handlePasswordModalOpen = () => setIsPasswordModalOpen(true);
    const handlePasswordModalClose = () => setIsPasswordModalOpen(false);

    return (
        <Box className={classes.profileContainer}>
            {/* Profile Image and Change Image Link */}
            <Avatar
                src="https://firebasestorage.googleapis.com/v0/b/deentire-application.appspot.com/o/0000noteImages%2Forg.webp?alt=media&token=7e49290f-6043-484f-98f3-f600a403beb3" // Replace with user's profile image source
                alt="Profile Image"
                style = {{border: "2px solid black", marginBottom: "10px"}}
                sx={{
                    width: 72,         // Adjust width
                    height: 72,        // Adjust height
                    borderRadius: '60%', // Adjust to desired roundness
                }}
            />
            <Typography
                variant="body2"
                className={classes.changeImageLink}
                onClick={() => console.log("Change Image Clicked")}
            >
                Change Image Profile
            </Typography>

            {/* User Information Form */}
            <div style = {{display: "flex", flexDirection: "column", gap: "20px", width: "100%",
                marginTop: "30px"
            }}>
            <TextField
                label="Name"
                variant="outlined"
                className={classes.formField}
                defaultValue="Samafale Organization Co."
            />
            <TextField
                label="Phone"
                variant="outlined"
                className={classes.formField}
                defaultValue="+252 616 549198"
            />
            <TextField
                label="Phone"
                variant="outlined"
                className={classes.formField}
                defaultValue="+252 616 549198"
            />
            </div>

            <div style = {{display: "flex", flexDirection: "column", gap: "15px", width: "100%",
                marginTop: "25px"
            }}>
            <Button variant="contained" 
             style = {{height: "45px", background: constants.pColor, color: "white",
               fontWeight: "bold",  fontSize: "14px"
            }}>
                Update Profile
            </Button>
            <Button
                variant="outlined"
                style = {{height: "45px", borderColor: constants.pColor, background: "white",
                    color: constants.pColor, fontWeight: "bold", fontSize: "14px"
                }}
                onClick={handlePasswordModalOpen}
            >
                Change Password
            </Button>
            </div>

            {/* Change Password Modal */}
            <Modal
                open={isPasswordModalOpen}
                onClose={handlePasswordModalClose}
                aria-labelledby="change-password-modal-title"
                aria-describedby="change-password-modal-description"
            >
                <Box className={classes.modalContent}>
                    <Typography style = {{fontWeight:"bold", fontSize: "18px"}}>
                        Change Password
                    </Typography>
                   
                    <div style = {{display: "flex", flexDirection: "column", gap: "10px", width: "100%",
                marginTop: "20px"
            }}>
                    <TextField
                        label="Previous password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        className={classes.modalField}
                    />
                    <TextField
                        label="New password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        className={classes.modalField}
                    />
                    <TextField
                        label="Confirm password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        className={classes.modalField}
                    />
                    </div>
                    <Button
                        variant="contained"
                        style = {{height: "45px", background: constants.pColor, color: "white",
                            fontWeight: "bold", width: "100%", marginTop: "15px", fontSize: "16px"
                         }}
                        onClick={() => console.log("Password Changed")}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Profile;
